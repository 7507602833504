import React from "react"
import { PageContainer, ContentContainer} from "./ContentStyle"

const Content = (props) => (
    <PageContainer>
        <ContentContainer>
        {props.children}
        </ContentContainer>
    </PageContainer>
)

export default Content