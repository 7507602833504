import React from "react"

const InVooDoo = () => (
    <p>
        InVooDoo<br/>
        11916 Tivoli Park Row,<br/>
        San Diego CA 92128,<br/>
        United States<br/>
    </p>
)
export default InVooDoo