import styled from "styled-components"

export const PageContainer = styled.div`
    display: flex;
    justify-content: center;
    
    align-items: center;
`

export const ContentContainer = styled.div`
    width: 60%;

    padding-top: 2em;
    padding-bottom: 2em;

    @media (max-width: 1000px) {
        width: 90%;
    }
`